import React from 'react';
import { Link } from 'react-router-dom';

import urls from 'constants/urls';
import { preserveStubsUrl } from 'utils/general';
import { Banner } from './Banner';
import UrgencyBanner from './UrgencyBanner';
import { useUrgencyBannerType } from './useUrgencyBannerType';

export default function UrgencyBannerSelector() {
  const [type, payload] = useUrgencyBannerType();

  switch (type) {
    case null:
      return null;
    case 'payment': {
      const link = preserveStubsUrl(urls.myOrdersPage);
      return (
        <Banner type="danger">
          Payment failed. To pay and receive your order, <Link to={link}>go to my orders</Link>
        </Banner>
      );
    }
    default:
      return <UrgencyBanner {...payload} type={type} />;
  }
}
