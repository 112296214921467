import {
  RemoveOutline as CloseIcon,
  Information as InfoIcon,
  Warning as WarningIcon,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Banner.scss';

export interface BannerProps {
  type: 'info' | 'warning' | 'danger';
  children: ReactNode | { __html: string };
  onClose?: JSX.IntrinsicElements['button']['onClick'];
}

export function Banner({ type, children, onClose }: BannerProps) {
  const content =
    children && typeof children === 'object' && '__html' in children ? (
      <span
        className={styles.html}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={children}
      />
    ) : (
      <span className={styles.html}>{children}</span>
    );

  return (
    <div
      data-testid="urgencyBanner"
      className={classNames(styles.banner, styles[type])}
      aria-live="polite"
    >
      <div className={styles.content}>
        {type === 'info' && <InfoIcon className={styles.icon} data-testid="InfoIcon" />}
        {['warning', 'danger'].includes(type) && (
          <WarningIcon className={styles.icon} data-testid="WarningIcon" />
        )}

        {content}
      </div>
      {onClose ? (
        <button
          className={styles.closeBtn}
          type="button"
          aria-label="close message"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      ) : null}
    </div>
  );
}
