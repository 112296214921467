import { useMemo } from 'react';
import { Experiments, GetVariantsByExperiment } from 'constants/monetateExperiments';
import { useExperiments } from './useExperiments';

interface DecisionResult<TVariant = string, TPayload = unknown> {
  variant: TVariant;
  payload: TPayload;
  trackExperimentView: (trackAll?: boolean) => void;
}

export function useDecisionById<
  TPayload = unknown,
  T extends keyof Experiments = keyof Experiments,
>(id: T): DecisionResult<GetVariantsByExperiment<T>, TPayload>;
export function useDecisionById(id: string) {
  const { getDecisionById } = useExperiments();

  return useMemo(() => getDecisionById(id), [getDecisionById, id]);
}
